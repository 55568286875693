import { excuseApi } from '../commons/http';


class Account {
  constructor(data = {}) {
    this.email = data.email;
    this.name = data.name;
    this.password = data.password;
    this.role = data.role;
    this.status = data.status;
  }

  mapData(data) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.role = data.role;
    this.status = data.status;
  }

  async save() {
    const res = await excuseApi({
      path: '/accounts',
      method: 'POST',
      body: {
        name: this.name,
        email: this.email,
        password: this.password,
        role: this.role,
        status: this.status,
      }
    });
    if (res.status === 200) {
      return { success: true, message: "Thêm tài khoản thành công" };
    }
    else{
      return { sucess: false, message: "Đã xảy ra lỗi trong quá trình thêm nhân viên"};
    }
  }

  async activeInActive() {
    const newStatus = this.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const res = await excuseApi({
      path: `/accounts/${this.id}`,
      method: 'PUT',
      body: {
        name: this.name,
        email: this.email,
        password: this.password,
        role: this.role,
        status: newStatus
      }
    });
    if (res.status === 200) {
      this.status = newStatus;
      return res.data.data;
    }
  }

  async getAccountById(id) {
    try {
      const res = await excuseApi({
        path: `/accounts/${id}`,
        method: 'GET',
      });
      if (res.status === 200) {
        return res.data.data;
      }
      else {
        return null;
      }
    }
    catch (error) {
      throw error;
    }
  }

  async editAccount(id) {
    try {
      const res = await excuseApi({
        path: `/accounts/${id}`,
        method: 'PUT',
        body: {
          name: this.name,
          email: this.email,
          password: this.password,
          role: this.role,
          status: this.status,
        }
      });
      if (res.status === 200) {
        return {sucess: true, message: "Thay đổi thông tin thành công!"};
      }
      else {
        return null;
      }
    }
    catch (error) {
      throw error;
    }
  }

  async changePassword(currentPassword,newPassword,confirmedPassword){
    const res = await excuseApi({
      path: '/current_account',
      method: 'GET',
    });
    if (res.status === 200) {
      if(newPassword === confirmedPassword){
        try{
          const res = await excuseApi({
            path: '/current_account',
            method: 'PUT',
            body: {
              current_password: currentPassword,
              password: newPassword,
            }
          })
          if(res.status === 200){
            return { success: true, message: "Đổi mật khẩu thành công" };
          }
        }
        catch(error){
          return { success: false, message: "Mật khẩu hiện tại sai" };
        }
      }
      else{
        return { success: false, message: "Mật khẩu mới và nhập lại mật khẩu không giống nhau!" };
      }
    }
    else {
      return null;
    }
  }
}

export default Account;
