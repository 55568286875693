import React, { useEffect, useState } from "react";
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container, MenuItem, CircularProgress, Grid, Box, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import Users from "../../collections/users";
import Products from "../../collections/products";
import Order from "../../models/order";

const AddOrder = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState(new Users());
    const [products, setProducts] = useState([]);
    const [orderItems, setOrderItems] = useState([{ id: 1, product: '', quantity: 1 }]);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [address, setAddress] = useState([]);
    const [userId, setUserId] = useState([]);
    const [errors, setErrors] = useState('');
    const [shippingCost,setShippingCost] = useState();
    useEffect(() => {
        if (mounted) {
            return;
        }
        setMounted(true);

        const fetchUsers = async () => {
            setLoading(true);
            await users.fetch();
            setLoading(false);
        };

        const fetchProducts = async () => {
            const listProducts = new Products();
            await listProducts.fetch();
            console.log(listProducts);
            setProducts(listProducts.items);
        }

        fetchUsers();
        fetchProducts();
        setLoading(false);
    }, []);

    const handleAddItem = () => {
        setOrderItems(prevItems => [
            ...prevItems,
            { id: prevItems.length + 1, product: '', quantity: 1 }
        ]);
    };

    const handleProductChange = (index, value) => {
        const newItems = [...orderItems];
        newItems[index].product = value;
        setOrderItems(newItems);
    };

    const handleCustomerChange = (newValue) => {
        if (newValue) {
            setAddress(newValue.address);
            setUserId(newValue.id);
        } else {
            setAddress("");
            setUserId(null);
        }
    }

    const handleRemoveItem = (index) => {
        const newItems = orderItems.filter((_, i) => i !== index);
        const updatedItems = newItems.map((item, i) => ({ ...item, id: i + 1 }));
        setOrderItems(updatedItems);
    };

    const handleQuantityChange = (index, value) => {
        const newItems = [...orderItems];
        newItems[index].quantity = value;
        setOrderItems(newItems);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let validationErrors = {};
        if (!shippingCost) validationErrors.shippingCost = 'Phí Ship không được để trống!';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        else {
            const orderLineItems = orderItems.map(item => ({
                product_id: item.product,
                quantity: item.quantity,
                note: item.note || '',
            }));
            const order = new Order({
                user_id: userId,
                shipping_price: shippingCost,
                shipping_address: data.get('address'),
                order_line_items: orderLineItems,
                note: data.get('note'),
                order_type: data.get('orderType'),
            })
            try {
                const res = await order.addOrder();
                if (res.success) {
                    toast.success(res.messenge, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    navigate('/orders');
                }
                else {
                    toast.error(res.messenge, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            catch (error) {
                let errorMessage = "Đã xảy ra lỗi không xác định!";
                if (error.response?.data?.error) {
                    if (Array.isArray(error.response.data.error)) {
                        errorMessage = error.response.data.error.join(", ");
                    } else if (typeof error.response.data.error === "string") {
                        errorMessage = error.response.data.error;
                    } else if (typeof error.response.data.error === "object") {
                        errorMessage = JSON.stringify(error.response.data.error);
                    }
                }
                toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    };

    if (loading) {
        return <CircularProgress />;
    }
    console.log(orderItems);
    return (
        <Container>
            <Box component="form" onSubmit={handleSubmit}>
                <Autocomplete
                    options={users.items}
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(event, newValue) => handleCustomerChange(newValue)}
                    renderInput={(params) => <TextField {...params} label="Chọn Khách Hàng" margin="normal" variant="outlined" />}
                    fullWidth
                />
                <TextField
                    id="address"
                    name="address"
                    label="Địa chỉ"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Autocomplete
                    value={shippingCost}
                    onChange={(event, newValue) => {
                        setShippingCost(newValue);
                    }}
                    options={[0, 10000, 15000, 25000, 29000, 39000]}
                    getOptionLabel={(option) => {
                        return option === 0 ? "Miễn phí" : new Intl.NumberFormat().format(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (/^\d*$/.test(newInputValue)) {
                          setShippingCost(newInputValue === '' ? '' : Number(newInputValue));
                        }
                      }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tiền Ship"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.shippingCost}
                            helperText={errors.shippingCost}
                            inputProps={{
                                ...params.inputProps,
                                onKeyPress: (e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                },
                              }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option === 0 ? "0" : new Intl.NumberFormat().format(option)}
                        </li>
                    )}
                    freeSolo
                />
                <TextField
                    id="note"
                    name="note"
                    label="Ghi Chú"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    select
                    id="orderType"
                    name="orderType"
                    label="Chọn Gói Sản Phẩm"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    defaultValue="MONTHLY_PACKAGE_ORDER"
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    <MenuItem value="MONTHLY_PACKAGE_ORDER">Gói Tháng</MenuItem>
                    <MenuItem value="INSTANT_ORDER">Mua Lẻ</MenuItem>
                </TextField>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Sản Phẩm</TableCell>
                                <TableCell>Số Lượng</TableCell>
                                <TableCell>Ghi Chú</TableCell>
                                <TableCell>Xóa</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderItems.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            options={products}
                                            getOptionLabel={(option) => option.name || ""}
                                            value={products.find(p => p.id === item.product) || null}
                                            onChange={(event, newValue) => handleProductChange(index, newValue ? newValue.id : '')}
                                            renderInput={(params) => <TextField {...params} label="Sản Phẩm" fullWidth />}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="text"
                                            value={item.quantity}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    handleQuantityChange(index, value);
                                                }
                                            }}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="text"
                                            value={item.note || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newItems = [...orderItems];
                                                newItems[index].note = value;
                                                setOrderItems(newItems);
                                            }}
                                            fullWidth
                                            label="Ghi Chú"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleRemoveItem(index)}>X</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={2} marginTop={2} direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                        <Button variant="contained" onClick={handleAddItem}>Thêm Sản Phẩm</Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit">Lưu Đơn Hàng</Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default AddOrder;