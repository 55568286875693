import { excuseApi } from "../commons/http";

class Product {
    constructor(data = {}) {
        this.name = data.name;
        this.description = data.description;
        this.image = data.image;
        this.expire_days = data.expire_days;
        this.convert_rate = data.convert_rate;
    }

    mapData(data) {
        this.id = data.id
        this.name = data.name;
        this.description = data.description;
        this.image = data.image;
        this.expire_days = data.expire_days;
        this.convert_rate = data.convert_rate;
    }

    async createProduct() {
        const res = await excuseApi({
            path: '/products',
            method: 'POST',
            body: {
                name: this.name,
                description: this.description,
                image: this.image,
                expire_days: this.expire_days,
                convert_rate: this.convert_rate,
            }
        });
        if (res.status === 200) {
            const productId = res.data.data.id;
            return { success: true, message: "Thêm sản phẩm thành công", id:productId };
        }
        else {
            return { success: false, message: "Thêm sản phẩm thất bại" };
        }
    }

    async getProductById(id) {
        try {
            const res = await excuseApi({
                path: `/products/${id}`,
                method: 'GET',
            });
            if (res.status === 200) {
                return res.data.data;
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async updateProduct(id) {
        try {
            const res = await excuseApi({
                path: `/products/${id}`,
                method: 'PUT',
                body: {
                    name: this.name,
                    description: this.description,
                    image: this.image,
                    expire_days: this.expire_days,
                    convert_rate: this.convert_rate,
                }
            });
            if (res.status === 200) {
                return {sucess: true, message:"Thay đổi thông tin sản phẩm thành công"};
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }
}
export default Product;