import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import ModeIcon from '@mui/icons-material/Mode';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Products from "../../collections/products";

const ListProducts = (props) => {
    const navigate = useNavigate();
    const [products, setProducts] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showProducts, setShowProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchProducts = async () => {
        const listProducts = new Products();
        await listProducts.fetch();
        console.log(listProducts);
        setProducts(listProducts.items);
        setShowProducts(listProducts.items);
    }

    useEffect(() => {
        props.setPageTitle("Danh sách sản phẩm");
        fetchProducts();
        setLoading(false);
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditProduct = (id, event) => {
        event.stopPropagation();
        navigate(`/products/edit/${id}`);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        const filteredProducts = products.filter(product =>
            product.name.toLowerCase().includes(value.toLowerCase())
        );
        setShowProducts(filteredProducts);
        setPage(0);
    };

    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ mt: 3, ml: '250px' }}
            />
        );
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 3 }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/createproduct')}
                        sx={{
                            textTransform: 'none',
                            height: "px",
                            fontSize: "15px",
                            backgroundColor: "transparent",
                        }}
                        startIcon={<AddIcon />}
                    >
                        Thêm sản phẩm
                    </Button>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                    <TextField
                        sx={{
                            width: "300px",
                            borderRadius: "30px",
                            fontSize: "15px",
                            backgroundColor: "transparent",
                        }}
                        onChange={handleSearchChange}
                        id="search-results-menu"
                        name="name"
                        type="text"
                        placeholder="Tìm sản phẩm..."
                        InputProps={{
                            endAdornment: <SearchIcon />,
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="orders table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderRight: 1, borderColor: 'grey.200' }}>ID</TableCell>
                            <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>Image</TableCell>
                            <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>Tên sản phẩm</TableCell>
                            <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>Mô tả</TableCell>
                            <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>Hạn sử dụng</TableCell>
                            <TableCell align="left" sx={{ width: 100, borderRight: 1, borderColor: 'grey.200' }}>Tỉ lệ chuyển đổi</TableCell>
                            <TableCell align="left" sx={{ width: 100, borderRight: 1, borderColor: 'grey.200' }}>Hành động</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => {
                            return (
                                <TableRow
                                    key={product.ID}
                                    hover
                                    onClick={() => navigate(`/products/edit/${product.id}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell component="th" scope="row" sx={{ borderRight: 1, borderColor: 'grey.200' }}>{product.id}</TableCell>
                                    <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>
                                        {product.image ? <img src={product.image} alt={product.name} style={{ width: '100px', height: '100px' }} /> : null}
                                    </TableCell>
                                    <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>{product.name}</TableCell>
                                    <TableCell align="left" sx={{ width: 150, borderRight: 1, borderColor: 'grey.200' }}>{product.description}</TableCell>
                                    <TableCell align="left" sx={{ width: 100, borderRight: 1, borderColor: 'grey.200' }}>{product.expire_days}</TableCell>
                                    <TableCell align="left" sx={{ width: 100, borderRight: 1, borderColor: 'grey.200' }}>{product.convert_rate}</TableCell>
                                    <TableCell align="left" sx={{ width: 100, borderRight: 1, borderColor: 'grey.200' }}><Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(event) => handleEditProduct(product.id, event)}
                                        startIcon={<ModeIcon />}
                                        sx={{ mr: 2, width: "150px" }}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={showProducts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Số hàng mỗi trang"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} của ${count}`}
                />
            </TableContainer>
        </Container>
    )
}

export default ListProducts;