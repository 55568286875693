import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Checkbox, TextField, Select, MenuItem, Box, Typography, FormControl, InputLabel, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import Chip from '@mui/material/Chip';

import Orders from "../../collections/orders";
import Order from "../../models/order";
import Account from "../../models/account";
import Accounts from "../../collections/accounts";

const ListOrders = (props) => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [searchTerm, setSearchTerm] = useState("");
    const [financialStatus, setFinancialStatus] = useState('');
    const [totalOrders, setTotalOrders] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [createdByFilter, setCreatedByFilter] = useState('');

    useEffect(() => {
        props.setPageTitle("Danh sách đơn hàng");

        const fetchAccounts = async () => {
            const accountList = new Accounts();
            await accountList.fetch();
            setAccounts(accountList.items);
        };

        const fetchOrders = async () => {
            const ApiPage = page + 1;
            const listOrders = new Orders();
            await listOrders.fetch(ApiPage, rowsPerPage);
            const sortedOrders = listOrders.items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            const account = new Account();
            const ordersWithAccount = await Promise.all(sortedOrders.map(async (order) => {
                const accountData = await account.getAccountById(order.created_by);
                return {
                    ...order,
                    created_by_name: accountData?.name || 'N/A',
                };
            }));

            setOrders(ordersWithAccount);
            setFilteredOrders(ordersWithAccount);
            setTotalOrders(listOrders.total);
        }

        fetchAccounts();
        fetchOrders();
    }, [page, rowsPerPage])
    console.log(createdByFilter);
    useEffect(() => {
        const fetchOrders = async () => {
            const ApiPage = page + 1;
            try {

                const order = new Order({
                    start_date: dayjs(dateRange[0]).isValid() ? dayjs(dateRange[0]).format("YYYY-MM-DD") : null,
                    end_date: dayjs(dateRange[1]).isValid() ? dayjs(dateRange[1]).format("YYYY-MM-DD") : null,
                    financial_status: financialStatus || null,
                    name: searchTerm || null,
                    user: [{ name: searchTerm }] || null,
                    created_by: createdByFilter || null,
                });
                const res = await order.getOrderByDate(ApiPage, rowsPerPage);

                const account = new Account();
                const ordersWithAccount = await Promise.all((res?.data || []).map(async (order) => {
                    const accountData = await account.getAccountById(order.created_by);
                    return {
                        ...order,
                        created_by_name: accountData?.name || 'N/A',
                    };
                }));

                setFilteredOrders(ordersWithAccount);
                setTotalOrders(res.total);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };
        fetchOrders();
    }, [dateRange, financialStatus, searchTerm, orders, page, rowsPerPage, createdByFilter]);

    const displayedOrders = filteredOrders;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('vi-VN', options);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedOrders([]);
        } else {
            const allOrderIdsOnPage = displayedOrders.map(order => order.id);
            setSelectedOrders(allOrderIdsOnPage);
        }
        setSelectAll(!selectAll);
    };

    const handleSelectOrder = (orderId) => {
        let updatedSelectedOrders;
        if (selectedOrders.includes(orderId)) {
            updatedSelectedOrders = selectedOrders.filter(id => id !== orderId);
        } else {
            updatedSelectedOrders = [...selectedOrders, orderId];
        }
        setSelectedOrders(updatedSelectedOrders);

        if (updatedSelectedOrders.length === displayedOrders.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };

    const handleExportLabel = async () => {
        const labels = new Order();
        const res = await labels.exportLabel(selectedOrders);
        if (res.data) {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
            const downloadLink = window.URL.createObjectURL(pdfBlob);
            downloadPDF(downloadLink);
        }
    };

    const downloadPDF = (link) => {
        const linkElement = document.createElement('a');
        linkElement.href = link;
        linkElement.download = `label.pdf`;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    };

    const handleDateChange = (index, newValue) => {
        const newDateRange = [...dateRange];
        newDateRange[index] = newValue;
        setDateRange(newDateRange);
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount).replace("₫", "");
    };

    return (
        <Container sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/orders/new')}
                    sx={{ textTransform: 'none' }}
                    startIcon={<AddIcon />}
                >
                    Thêm đơn hàng mới
                </Button>

                {selectedOrders.length > 0 && (
                    <Button
                        variant="contained"
                        onClick={handleExportLabel}
                        sx={{ textTransform: 'none', mx: 2 }}
                    >
                        Tải xuống đơn hàng
                    </Button>
                )}

                <TextField
                    label="Tìm kiếm khách hàng hoặc mã đơn hàng"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value)
                        setPage(0);
                    }}
                    sx={{ width: '300px' }}
                />
            </Box>

            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Lọc kết quả:
            </Typography>
            <Grid container spacing={4} sx={{ mb: 2 }}>
                <Grid item xs={3}>
                    <DatePicker
                        label="Từ ngày"
                        value={dateRange[0]}
                        onChange={(newValue) => {
                            handleDateChange(0, newValue);
                            setPage(0);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                        inputFormat="DD/MM/YYYY"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePicker
                        label="Đến ngày"
                        value={dateRange[1]}
                        onChange={(newValue) => {
                            handleDateChange(1, newValue);
                            setPage(0);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                        inputFormat="DD/MM/YYYY"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel id="created-by-label" shrink>Người tạo đơn</InputLabel>
                        <Select
                            labelId="created-by-label"
                            value={createdByFilter}
                            onChange={(e) => {
                                setCreatedByFilter(e.target.value);
                                setPage(0);
                            }}
                            label="Người tạo đơn"
                            displayEmpty
                        >
                            <MenuItem value="">Tất cả</MenuItem>
                            {accounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>
                                    {account.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                        <InputLabel id="financial-status-label" shrink>Trạng thái thanh toán</InputLabel>
                        <Select
                            labelId="financial-status-label"
                            value={financialStatus}
                            onChange={(e) => {
                                setFinancialStatus(e.target.value);
                                setPage(0);
                            }}
                            label="Trạng thái thanh toán"
                            displayEmpty
                        >
                            <MenuItem value="">Tất cả</MenuItem>
                            <MenuItem value="PAID">Đã thanh toán</MenuItem>
                            <MenuItem value="SKIPPED">Bỏ qua</MenuItem>
                            <MenuItem value="UNPAID">Chưa thanh toán</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell>Ngày</TableCell>
                            <TableCell>Tên khách hàng</TableCell>
                            <TableCell>Mã đơn hàng</TableCell>
                            <TableCell>Tiền ship</TableCell>
                            <TableCell>Địa chỉ</TableCell>
                            <TableCell>Trạng thái</TableCell>
                            <TableCell>Thanh toán</TableCell>
                            <TableCell>Tạo bởi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedOrders.map((order, index) => (
                            <TableRow
                                key={index}
                                onClick={() => navigate(`/orders/${order.id}`)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                            >
                                <TableCell onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        checked={selectedOrders.includes(order.id)}
                                        onChange={() => handleSelectOrder(order.id)}
                                    />
                                </TableCell>
                                <TableCell>{formatDate(order.created_at)}</TableCell>
                                <TableCell>{order.user.name}</TableCell>
                                <TableCell>{order.name}</TableCell>
                                <TableCell>{formatCurrency(order.shipping_price)}</TableCell>
                                <TableCell>{order.shipping_address}</TableCell>
                                <TableCell>
                                    {order.status === 'OPEN' ? (
                                        <Chip label="Mới" color="warning" />
                                    ) : order.status === 'SENT_INVOICE' ? (
                                        <Chip label="Đã gửi hóa đơn" color="success" />
                                    ) : (
                                        <Chip label={order.status} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {order.financial_status === 'UNPAID' ? (
                                        <Chip label="Chưa thanh toán" sx={{ backgroundColor: 'red', color: 'white' }} />
                                    ) : order.financial_status === 'PAID' ? (
                                        <Chip label="Đã thanh toán" color="success" />
                                    ) : order.financial_status === 'SKIPPED' ? (
                                        <Chip label="Bỏ qua" sx={{ backgroundColor: 'yellow', color: 'black' }} />
                                    ) : null}
                                </TableCell>
                                <TableCell>{order.created_by_name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalOrders}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Số hàng mỗi trang"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} của ${count}`}
                />
            </TableContainer>
        </Container>
    );
};

export default ListOrders;