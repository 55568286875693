import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { toast } from 'react-toastify';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Product from "../../models/product";
import Price from "../../models/price";

const EditProduct = (props) => {
    const navigate = useNavigate();
    const [productData, setProductData] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [error, setError] = useState('');
    const [newImage, setNewImage] = useState(null);
    const [priceList, setPriceList] = useState([]);

    useEffect(() => {
        props.setPageTitle("Chỉnh sửa sản phẩm");
        const fetchProduct = async () => {
            try {
                const product = new Product();
                const data = await product.getProductById(id);
                setProductData(data);

                const price = new Price();
                const priceData = await price.getPriceById(id);

                if (priceData) {
                    setPriceList(priceData);
                    setProductData((prevData) => ({
                        ...prevData,
                        ...priceData
                    }));
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchProduct();
    }, [id, props]);

    const uploadImage = async (imageFile) => {
        let data = new FormData();
        data.append('file', imageFile);
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: 'https://api.maysfarm.vn/api/v1/images',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZ3V5ZW50aGFpMjgxMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJleHAiOjEwMDAwMDAwMDAwMDE3MjQ4NDY5NTh9.KtSXIt4Du_lhqfZNwyGCETlCW9BaIXUujjSW60SfaqY',
            },
            data: data
        };
        try {
            const response = await axios.request(config);
            return response.data.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const removeImage = () => {
        setNewImage(null);
        setProductData((prevData) => ({
            ...prevData,
            image: null
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let validationErrors = {};
        if (!data.get('new_name')) validationErrors.name = 'Tên sản phẩm không được để trống!';
        if (!data.get('new_description')) validationErrors.description = 'Mô tả không được để trống!';
        if (!newImage && !productData.image && !data.get('new_image')) validationErrors.image = 'Hình ảnh không được để trống!';
        if (!data.get('new_expire_days')) validationErrors.expire_days = 'Hạn sử dụng không được để trống!';
        if (!data.get('new_price')) validationErrors.price = 'Đơn giá không được để trống!';
        if (!data.get('new_convert_rate')) validationErrors.convert_rate = 'Tỉ lệ chuyển đổi không được để trống!';

        setError(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const imageFile = data.get('new_image');
            let imageName = productData.image;
            if (imageFile) {
                imageName = await uploadImage(imageFile);
            }
            const product = new Product({
                name: data.get('new_name'),
                description: data.get('new_description'),
                image: imageName,
                expire_days: data.get('new_expire_days'),
                convert_rate: data.get('new_convert_rate'),
            })
            const currentDateTime = new Date().toISOString().split('.')[0];
            const price = new Price({
                product_id: productData.id,
                price: data.get('new_price'),
                actived_at: currentDateTime,
            })
            try {
                const response = await product.updateProduct(id);
                const responsePrice = await price.addPrice(id);
                if (response.sucess && responsePrice.success) {
                    toast.success(response.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    navigate("/products");
                }
            }
            catch (error) {
                console.log(error);
                throw error;
            }
        }

    }

    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ m: 3 }}
            />
        )
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={handleSubmit} flex={1}>
                        <TextField
                            id="new_name"
                            name="new_name"
                            label="Tên sản phẩm"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={productData.name}
                            onChange={(event) => setProductData({ ...productData, name: event.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.name}
                            helperText={error.name}
                        />
                        {productData.image && (
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    label="Hình ảnh"
                                    variant="outlined"
                                    value={newImage ? newImage.split('/').pop() : productData.image.split('/').pop()}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={removeImage}
                                                sx={{ ml: 1 }}
                                            >
                                                X
                                            </Button>
                                        ),
                                    }}
                                />
                                <Box sx={{ mt: 1 }}>
                                    <img src={newImage || productData.image} alt="Current product" style={{ width: '100%', height: 'auto' }} />
                                </Box>
                            </Box>
                        )}
                        {productData.image === null && (
                            <TextField
                                margin="normal"
                                fullWidth
                                name="new_image"
                                label="Hình ảnh"
                                id="new_image"
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                error={!!error.image}
                                helperText={error.image}
                            />
                        )}
                        <TextField
                            id="new_description"
                            name="new_description"
                            label="Mô tả"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={productData.description}
                            onChange={(event) => setProductData({ ...productData, description: event.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.description}
                            helperText={error.description}
                        />
                        <TextField
                            id="new_price"
                            name="new_price"
                            label="Thêm đơn giá mới"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={productData.price ? productData.price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }) : productData.price}
                            onChange={(event) => setProductData({ ...productData, price: event.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.description}
                            helperText={error.description}
                        />
                        <TextField
                            id="new_expire_days"
                            name="new_expire_days"
                            label="Hạn sử dụng"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={productData.expire_days}
                            onChange={(event) => setProductData({ ...productData, expire_days: event.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.expire_days}
                            helperText={error.expire_days}
                        />
                        <TextField
                            id="new_convert_rate"
                            name="new_convert_rate"
                            label="Tỉ lệ chuyển đổi"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={productData.convert_rate}
                            onChange={(event) => setProductData({ ...productData, convert_rate: event.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error.convert_rate}
                            helperText={error.convert_rate}
                        />
                        <TextField
                            label="Tỉ lệ chuyển đổi"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={new Date(productData.created_at).toLocaleString('vi-VN', { timeZone: 'Asia/Ho_Chi_Minh' })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Chỉnh sửa giá lần cuối"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={new Date(productData.updated_at).toLocaleString('vi-VN', { timeZone: 'Asia/Ho_Chi_Minh' })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            Lưu
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper} flex={1}>
                        <Table aria-label="price history table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Giá</TableCell>
                                    <TableCell>Thời gian kích hoạt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {priceList.map((price, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{price.price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}</TableCell>
                                        <TableCell>{new Date(price.actived_at).toLocaleString('vi-VN', { timeZone: 'Asia/Ho_Chi_Minh' })}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container>
    )
}

export default EditProduct;