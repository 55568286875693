import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { jwtDecode } from "jwt-decode";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthLayout from './commons/AuthLayout';

import SignIn from './components/SignIn';
import Home from './components/Home';
import VerticalMenu from './components/Layout/VerticalMenu';
import AppBar from './components/Layout/AppBar';

import NewStaff from './components/Staffs/NewStaff';
import EditStaff from './components/Staffs/EditStaff';
import Staffs from './components/Staffs/Staffs';
import ChangePassword from './components/Staffs/ChangePassword';
import Products from './components/Products/Products';
import ListOrders from './components/Orders/Orders';
import Homepage from './components/Homepage';
import CreateProduct from './components/Products/CreateProduct';
import EditProduct from './components/Products/EditProduct';
import Customers from './components/Users-Customers/Users';
import NewUser from './components/Users-Customers/NewUser';
import AddOrder from './components/Orders/AddOrder';
import OrderDetail from './components/Orders/OrderDetail';
import PaidProof from './components/Orders/PaidProof';
import SendInvoice from './components/Orders/SendInvoice';
import Packing from './components/Packing/Packing';
import FullPackingVideo from './components/Packing/FullPackingVideo';
import PackingVideo from './components/Packing/PackingVideo';

import './App.scss';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0e2a04',
    }
  },
  typography: {
    fontFamily: [
      'Cabin',
      'Oxygen',
      'Roboto',
      'Arial',
    ].join(',')
  }
});


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [controlRole, setControlRole] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [openMenu, setOpenMenu] = useState();
  const [userId, setUserId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      const userId = decodedToken.id;
      if (userRole) {
        setControlRole(userRole);
      }
      if (userId) {
        setUserId(userId);
      }
    }
    if (!token) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn]);

  const toggleMenu = (newOpen) => () => {
    setOpenMenu(newOpen);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loggedIn && <VerticalMenu setLoggedIn={setLoggedIn} openMenu={openMenu} />}
      <Container
        sx={{
          pl: { xs: 0, sm: 0, md: loggedIn ? 31.25 : 0, lg: loggedIn ? 31.25 : 0 },
          pr: { xs: 0, sm: 0, md: 0, lg: 0 },
          m: { xs: 0, sm: 0, md: 0, lg: 0 },
        }}
        maxWidth="100%"
      >
        {loggedIn && <AppBar pageTitle={pageTitle} toggleMenu={toggleMenu} />}
        <div onClick={toggleMenu(false)}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path='/' element={<Home />} />
              <Route path='/staffs' element={<Staffs key="staffs" setPageTitle={setPageTitle} controlRole={controlRole} navigate={navigate} />} />
              <Route path='/staffs/new' element={<NewStaff key="new_staff" setPageTitle={setPageTitle} controlRole={controlRole} />} />
              <Route path='/users' element={<Customers key="users" setPageTitle={setPageTitle} />} />
              <Route path='/users/new' element={<NewUser key="new_user" setPageTitle={setPageTitle} />} />
              <Route path='/staffs/edit/:id' element={<EditStaff key="edit_staff" setPageTitle={setPageTitle} controlRole={controlRole} />} />
              <Route path='/changepassword' element={<ChangePassword setPageTitle={setPageTitle} userId={userId} />} />
              <Route path='/products' element={<Products setPageTitle={setPageTitle} />} />
              <Route path='/createproduct' element={<CreateProduct setPageTitle={setPageTitle} />} />
              <Route path='/products/edit/:id' element={<EditProduct setPageTitle={setPageTitle} />} />
              <Route path='/orders' element={<ListOrders setPageTitle={setPageTitle} />} />
              <Route path='/customers' element={<Customers setPageTitle={setPageTitle} />} />
              <Route path='/customers/new' element={<NewUser setPageTitle={setPageTitle} />} />
              <Route path='/orders/new' element={<AddOrder setPageTitle={setPageTitle} />} />
              <Route path='/orders/:id' element={<OrderDetail setPageTitle={setPageTitle} />} />
              <Route path='/orders/:id/paid_proof'element={<PaidProof setPageTitle={setPageTitle} />} />
              <Route path='/orders/:id/send_invoice'element={<SendInvoice setPageTitle={setPageTitle} />} />
              <Route path='/packing'element={<Packing setPageTitle={setPageTitle} />} />
              <Route path='/full_packing_video'element={<FullPackingVideo setPageTitle={setPageTitle} />} />
              <Route path='/packing_video/:id'element={<PackingVideo setPageTitle={setPageTitle} />} />
            </Route>
            <Route path='/login' element={<SignIn key="login" setLoggedIn={setLoggedIn} setControlRole={setControlRole} />} />
            <Route path='*' element={<Navigate to='/' />} />
            <Route path="homepage" element={<Homepage />} />
          </Routes>
        </div>
      </Container>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
