import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Order from '../../models/order';

const PaidProof = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const {id} = useParams();
    useEffect(() => {
        props.setPageTitle('Thanh toán');
    }, [props]);

    const uploadImage = async (imageFile) => {
        let data = new FormData();
        data.append('file', imageFile);
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: 'https://api.maysfarm.vn/api/v1/images',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZ3V5ZW50aGFpMjgxMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJleHAiOjEwMDAwMDAwMDAwMDE3MjQ4NDY5NTh9.KtSXIt4Du_lhqfZNwyGCETlCW9BaIXUujjSW60SfaqY',
            },
            data: data
        };
        try {
            const response = await axios.request(config);
            return response.data.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setImageFile(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let validationErrors = {};
        if (!data.get('image')) validationErrors.image = 'Hình ảnh không được để trống';
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        else {
            const imageFile = data.get('image');
            const imageName = await uploadImage(imageFile);
            console.log(imageName);
            const order = new Order({
                paid_proof: imageName
            });
            console.log(order);
            try {
                const res = await order.markAsPaid(id);
                if (res.success) {
                    toast.success(res.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    navigate(`/orders/${id}`);
                }
                else {
                    setError(res.message);
                }
            } catch (error) {
                throw error;
            }
        }
    }

    return (
        <>
            <Container maxWidth="xs" sx={{ mt: 3 }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="image"
                        label="Hình ảnh thanh toán"
                        id="image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.image}
                        helperText={errors.image}
                        onChange={handleImageChange}
                    />
                    {selectedImage && (
                        <Box mt={2} textAlign="center">
                            <img src={selectedImage} alt="Selected" style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
                        </Box>
                    )}
                    <Typography sx={{ textAlign: "center", mt: 2, mb: 2 }} color="error">{error}</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Xác nhận thanh toán
                    </Button>
                </Box>
            </Container>
        </>
    )
}
export default PaidProof;