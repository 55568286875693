import React from "react";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import Account from "../../models/account";

const ChangePassword = (props) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    props.setPageTitle("Đổi mật khẩu");
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const currentPassword = data.get('current_password');
    const newPassword = data.get('new_password');
    const confirmedPassword = data.get('repeat_new_password');
    const account = new Account();
    try {
      const response = await account.changePassword(currentPassword, newPassword, confirmedPassword);
      console.log(response);
      if (!response.success) {
        setError(response.message);
      }
      else {
        toast.success("Đổi mật khẩu thành công", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate('/');
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  }
  return (
    <Container maxWidth="xs" sx={{ mt: 3 }}>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          id="current_password"
          name="current_password"
          label="Mật khẩu hiện tại"
          fullWidth
          margin="normal"
          required
          type="password"
          autoComplete="current_password"
        />
        <TextField
          id="new_password"
          name="new_password"
          label="Mật khẩu mới"
          fullWidth
          margin="normal"
          variant="outlined"
          required
          type="password"
          autoComplete="new"
        />
        <TextField
          id="repeat_new_password"
          name="repeat_new_password"
          label="Nhập lại mật khẩu mới"
          fullWidth
          margin="normal"
          variant="outlined"
          type="password"
          autoComplete="repeat_new_password"
        />
        <Typography sx={{ textAlign: "center", mt: 2, mb: 2 }} color="error">{error}</Typography>
        <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            variant="contained"
            type="submit"
          >
            Lưu
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default ChangePassword;