import List from "./list";
import Order from "../models/order";
import { excuseApi } from "../commons/http";

export default class Orders extends List {
    constructor() {
        super();
        this.total = 0;
    }
    async fetch(page,per_page) {
        const res = await excuseApi({
            path: '/orders',
            method: 'GET',
            params:{
                page: page,
                per_page: per_page,
            }
        })
        if (res.status === 200 || res.status === 201) {
            this.total = res.data.total;
            this.mapData(res.data.data);
        }
    }

    mapData(data = []) {

        for (const item of data) {
            const order = new Order();
            order.mapData(item);
            this.add(order);
        }
    }
}