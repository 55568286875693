import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Session from '../models/session';
import { Link } from 'react-router-dom';

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" to="https://maysfarm.vn/">
        May's Farm
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useLayoutEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const session = new Session({
      email: data.get('email'),
      password: data.get('password'),
    });
    try {
      const res = await session.login();
      console.log(res);
      const token = res.token;
      if (token) {
        localStorage.setItem('token', token);
        props.setLoggedIn(true);
        props.setControlRole(res.role);
        navigate('/');
      }
    }
    catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError("Sai tài khoản hoặc mật khẩu");
        } else if (error.response.status === 500) {
          setError("Server đang lỗi hoặc đang quá tải, vui lòng thử lại sau");
        } else {
          setError("Đã xảy ra lỗi không xác định, vui lòng thử lại sau");
        }
      } else {
        setError("Không thể kết nối đến server, vui lòng kiểm tra kết nối mạng");
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Typography sx={{ textAlign: "center" }} color="error">{error}</Typography>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

export default SignIn;
