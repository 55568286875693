import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Paper } from '@mui/material';

import Order from '../../models/order';

const PackingVideo = (props) => {
    const { id } = useParams();
    const [video, setVideo] = useState([]);

    useEffect(() => {
        props.setPageTitle("Chi tiết video đóng gói");
        const fetchVideos = async () => {
            const listVideos = new Order();
            try {
                const videosData = await listVideos.getFullPackingVideo();
                const selectedVideo = videosData.find(video => video.id === parseInt(id));
                setVideo(selectedVideo);
            }
            catch (error) {
                console.error("Error fetching videos:", error);
            }
        }
        fetchVideos();
    }, [id])
    console.log(video);
    if (!video) {
        return <div>Đang tải video...</div>;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
                <Box textAlign="center" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Chi tiết video
                    </Typography>
                    <Typography variant="body1">
                        <strong>ID:</strong> {video.id}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Mã đơn hàng:</strong> {video.order_code}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Ngày tạo:</strong> {new Date(video.created_at).toLocaleString()}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <video
                        // src={video.video_url}
                        src = {`http://localhost:8000/${video.order_code}.webm`}
                        controls
                        style={{
                            width: '100%',
                            maxWidth: '600px',
                            borderRadius: '8px',
                            boxShadow: '0 3px 10px rgba(0,0,0,0.2)'
                        }}
                    />
                </Box>
        </Container>
    );
}
export default PackingVideo;