import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';

import Users from '../../collections/users';

const Customers = (props) => {
    useEffect(() => {
        props.setPageTitle('Danh sách khách hàng');
    });

    const [users, setUsers] = useState(new Users());
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (mounted) {
            return;
        }
        setMounted(true);

        const fetchData = async () => {
            setLoading(true);
            await users.fetch();
            setFilteredUsers(users.items);
            setLoading(false);
        };

        fetchData();
    }, [mounted, users]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        const filtered = users.items.filter(user =>
            user.name.toLowerCase().includes(value)
            || user.phone_number.includes(value)
        );
        setFilteredUsers(filtered);
    };

    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ m: 3 }}
            />
        );
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 3 }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        href="/users/new"
                        sx={{ textTransform: 'none', mb: 2 }}
                        startIcon={<AddIcon />}
                    >
                        Thêm khách hàng
                    </Button>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                    <TextField
                        sx={{
                            width: "300px",
                            borderRadius: "30px",
                            fontSize: "15px",
                            backgroundColor: "transparent",
                        }}
                        onChange={handleSearchChange}
                        id="search-customers"
                        name="search"
                        type="text"
                        placeholder="Tìm khách hàng..."
                        InputProps={{
                            endAdornment: <SearchIcon />,
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Tên khách hàng</TableCell>
                            <TableCell align="left">Số điện thoại</TableCell>
                            <TableCell align="left">Địa chỉ</TableCell>
                            <TableCell align="left">Nguồn</TableCell>
                            <TableCell align="left">Ghi chú</TableCell>
                            <TableCell align="left">Thời gian tạo</TableCell>
                            <TableCell align="left">Thay đổi cuối</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                            <TableRow
                                key={user.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{user.id}</TableCell>
                                <TableCell align="left">{user.name}</TableCell>
                                <TableCell align="left">{user.phone_number}</TableCell>
                                <TableCell align="left">{user.address}</TableCell>
                                <TableCell align="left">{user.referral_source}</TableCell>
                                <TableCell align="left">{user.tags}</TableCell>
                                <TableCell align="left">
                                    {new Date(user.created_at).toLocaleString('vi-VN', { timeZone: 'Asia/Ho_Chi_Minh' })}
                                </TableCell>
                                <TableCell align="left">
                                    {new Date(user.updated_at).toLocaleString('vi-VN', { timeZone: 'Asia/Ho_Chi_Minh' })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredUsers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Số hàng mỗi trang"
            />
        </Container>
    );
};

export default Customers;