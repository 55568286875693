import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { toast } from 'react-toastify';

import Account from "../../models/account";

const EditStaff = (props) => {
    const navigate = useNavigate();
    const [accountData, setAccountData] = useState();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [error, setError] = useState('');

    useEffect(() => {
        props.setPageTitle("Chỉnh sửa nhân viên");
        const fetchAccount = async () => {
            const account = new Account();
            const data = await account.getAccountById(id);
            setAccountData(data);
            console.log(data);
            setLoading(false);
        }
        fetchAccount();
    }, [id, props])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let validationErrors = {};

        if (!data.get('new_name')) validationErrors.name = 'Tên không được để trống!';
        setError(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const account = new Account({
                name: data.get('new_name'),
                email: accountData.email,
                password: accountData.password,
                role: data.get('new_role'),
                status: accountData.status,
            })
            try {
                const response = await account.editAccount(id);
                if (response.sucess) {
                    toast.success(response.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    navigate("/staffs");
                }
                if (account.editAccount(id)) {
                    navigate("/staffs");
                }
            }
            catch (error) {
                console.log(error);
                throw error;
            }
        }
    }

    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ m: 3 }}
            />
        )
    }
    return (
        <Container maxWidth="xs" sx={{ mt: 3 }}>
            <Box component="form" onSubmit={handleSubmit}>
                <TextField
                    id="new_name"
                    name="new_name"
                    label="Tên"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={accountData.name}
                    onChange={(event) => setAccountData({ ...accountData, name: event.target.value })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!error.name}
                    helperText={error.name}
                />
                <TextField
                    id="new_role"
                    name="new_role"
                    label="Quyền truy cập mới"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    select
                    required
                    value={accountData.role}
                    onChange={(event) => setAccountData({ ...accountData, role: event.target.value })}
                >
                    <MenuItem value='ADMIN'>Quản lý</MenuItem>
                    <MenuItem value='STAFF'>Nhân viên</MenuItem>
                </TextField>
                <Button
                    variant="contained"
                    type="submit"
                >
                    Lưu
                </Button>
            </Box>
        </Container>
    )
}

export default EditStaff;



