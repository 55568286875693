import React, { useEffect, useRef, useState } from 'react';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Order from '../../models/order';
import Orders from '../../collections/orders';

const Packing = (props) => {
  const navigate = useNavigate();
  const [orderCode, setOrderCode] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoRecorded, setIsVideoRecorded] = useState(false);
  const mediaRecorderRef = useRef(null);
  const videoStreamRef = useRef(null);
  const chunks = useRef([]);
  const [order, setOrder] = useState(null);
  const [packingVideo, setPackingVideo] = useState(null);
  const [id, setId] = useState([]);
  const [packingVideoPath, setPackingVideoPath] = useState('');

  useEffect(() => {
    props.setPageTitle("Đóng hàng");
    const fetchOrders = async () => {
      if (!orderCode) {
        setId(null);
      }
      const listOrders = new Orders();
      await listOrders.fetch();
      const filteredOrders = listOrders.items.filter(order => order.name === orderCode);
      setOrder(filteredOrders);
      if (filteredOrders.length > 0) {
        setId(filteredOrders[0].id);
      } else {
        setId(null);
      }
    }
    fetchOrders();
  }, [orderCode]);

  const handleOrderCodeChange = (event) => {
    setOrderCode(event.target.value);
  };
  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { exact: 240 },
          height: { exact: 160 }
        },
        audio: true
      });
      videoStreamRef.current.srcObject = stream;
      videoStreamRef.current.play();

      const options = { 
        mimeType: 'video/webm; codecs=vp8',
        videoBitsPerSecond: 2000000
      };
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;
      chunks.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing media devices.', error);
      toast.error('Không thể truy cập thiết bị quay video.');
    }
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    videoStreamRef.current.srcObject.getTracks().forEach(track => track.stop());
    videoStreamRef.current.style.display = 'none';

    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunks.current, { type: chunks.current[0]?.type || 'video/webm' });
      const videoUrl = URL.createObjectURL(blob);
      setVideoUrl(videoUrl);
      setPackingVideo(blob);
      setIsVideoRecorded(true);
    };

    setIsRecording(false);
  };

  // const uploadVideo = async () => {
  //   let formData = new FormData();
  //   formData.append('file', packingVideo, 'packing-video.webm');
  //   let config = {
  //     method: 'POST',
  //     maxBodyLength: Infinity,
  //     url: 'https://api.maysfarm.vn/api/v1/images',
  //     headers: {
  //       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZ3V5ZW50aGFpMjgxMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJleHAiOjEwMDAwMDAwMDAwMDE3MjQ4NDY5NTh9.KtSXIt4Du_lhqfZNwyGCETlCW9BaIXUujjSW60SfaqY',
  //     },
  //     data: formData
  //   };
  //   try {
  //     const res = await axios.request(config);
  //     return res.data.data;
  //   }
  //   catch (error) {
  //     throw error;
  //   }
  // }

  const handleSendVideo = async (videoPath) => {
    // const video = await uploadVideo();
    
    const sendVideo = new Order({
      name: orderCode,
      video_url: videoPath,
    });
    try {
      const response = await sendVideo.createPackingVideo();
      if (response.success) {
        toast.success(response.messenge, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate("/orders");
      }
    }
    catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleDownloadVideo = () => {
    const link = document.createElement('a');
    const fileName = `${orderCode}.webm`;
    link.href = videoUrl;
    link.download = fileName;
    link.click();

    //Chỗ này trở xuống là lưu file
    const videoPath = `C:\\Users\\Julai\\Downloads\\${fileName}`;
    setPackingVideoPath(videoPath);

    return videoPath;
  };

  const handleDownloadAndSend = async () => {
    const videoPath = await handleDownloadVideo(); 
    await handleSendVideo(videoPath);
  };

  return (
    <Container sx={{ mt: 3 }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <TextField
          label="Nhập mã đơn hàng"
          variant="outlined"
          value={orderCode}
          onChange={handleOrderCodeChange}
          sx={{ mr: 2, width: '300px', height: '56px', fontSize: '20px' }}
          InputProps={{
            style: { fontSize: '20px', height: '56px' }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={isRecording ? handleStopRecording : handleStartRecording}
          sx={{ height: '56px', fontSize: '20px', padding: '0 16px' }}
        >
          {isRecording ? 'Dừng Quay' : 'Bắt đầu Quay'}
        </Button>
      </Box>

      <Box mt={2} textAlign="center">
        <video ref={videoStreamRef} style={{ width: '500px' }} autoPlay muted></video>
      </Box>

      {videoUrl && (
        <Box mt={2} textAlign="center">
          <Typography variant="h6">Video Đã Ghi:</Typography>
          <video src={videoUrl} controls style={{ width: '500px' }}></video>
        </Box>
      )}

      {isVideoRecorded && (
        <Box mt={2} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadAndSend}
            sx={{ height: '56px', fontSize: '20px', padding: '0 16px' }}
          >
            Tải Video
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Packing;