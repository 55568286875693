import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';

import Accounts from '../../collections/accounts';

const Staffs = (props) => {
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState(new Accounts());
    const [loading, setLoading] = useState(true);
    const controlRole = props.controlRole;
    const [fetched, setFetched] = useState(false);

    const fetchAccounts = async () => {
        if (controlRole === 'ADMIN' && !fetched) {
            setLoading(true);
            const newAccounts = new Accounts();
            await newAccounts.fetch();
            setAccounts(newAccounts);
            setLoading(false);
            setFetched(true);
        }
    };

    console.log(accounts);

    useEffect(() => {
        props.setPageTitle('Danh sách nhân viên');
        fetchAccounts();
    });

    const handleChangeStatus = async (account) => {
        await account.activeInActive();
        setAccounts({ ...accounts });
    };

    if (controlRole === 'STAFF') {
        return (
            <Typography component="h1" variant="h5" sx={{ textAlign: "center", mt: 5 }}>
                Bạn không đủ thẩm quyền để truy cập vào tính năng này
            </Typography>
        );
    }

    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ m: 3 }}
            />
        );
    }

    return (
        <Container sx={{ mt: 3 }}>
            <>
                <Button
                    variant="outlined"
                    onClick={() => navigate('/staffs/new')}
                    sx={{ textTransform: 'none', mb: 2 }}
                    startIcon={<AddIcon />}
                >
                    Thêm nhân viên
                </Button>
                <TableContainer component={Paper}>
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: '1rem' }}>ID</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left">Họ tên nhân viên</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left">Email</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left">Quyền truy cập</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left">Trạng thái</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left">Hành động</TableCell>
                                <TableCell sx={{ fontSize: '1rem' }} align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accounts.items.map((account) => (
                                <TableRow
                                    key={account.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {account.id}
                                    </TableCell>
                                    <TableCell align="left">{account.name}</TableCell>
                                    <TableCell align="left">{account.email}</TableCell>
                                    <TableCell align="left">
                                        {account.role === 'ADMIN' ? 'Quản lý' : 'Nhân viên'}
                                    </TableCell>
                                    <TableCell align="left" sx={{ color: account.status === 'ACTIVE' ? 'green' : 'red' }}>
                                        {account.status === 'ACTIVE' ? 'Hoạt động' : 'Dừng hoạt động'}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                textTransform: 'none',
                                                mb: 1,
                                                color: account.status === 'ACTIVE' ? 'red' : 'green',
                                                borderColor: account.status === 'ACTIVE' ? 'red' : 'green',
                                            }}
                                            onClick={() => handleChangeStatus(account)}
                                        >
                                            {account.status === 'ACTIVE' ? 'Dừng hoạt động' : 'Hoạt động'}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{ textTransform: 'none', mb: 1, textAlign: 'center' }}

                                            onClick={() => navigate(`/staffs/edit/${account.id}`)}
                                        >
                                            Chỉnh sửa
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </Container>
    );
};

export default Staffs;