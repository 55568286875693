import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import axios from 'axios';

import Product from '../../models/product';
import Price from '../../models/price';

const CreateProduct = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [errors, setErrors] = useState('');
    const [imageChange, setImageChange] = useState(false);
    const [uploadedImageName, setUploadedImageName] = useState('');
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        props.setPageTitle('Tạo mới sản phẩm');
    }, [props]);

    const uploadImage = async (imageFile) => {
        let data = new FormData();
        data.append('file', imageFile);
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: 'https://api.maysfarm.vn/api/v1/images',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZ3V5ZW50aGFpMjgxMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJleHAiOjEwMDAwMDAwMDAwMDE3MjQ4NDY5NTh9.KtSXIt4Du_lhqfZNwyGCETlCW9BaIXUujjSW60SfaqY',
            },
            data: data
        };
        try {
            const response = await axios.request(config);
            return response.data.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            setImageChange(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let validationErrors = {};
        if (!data.get('name')) validationErrors.name = 'Tên sản phẩm không được để trống';
        if (!data.get('description')) validationErrors.email = 'Mô tả không được để trống';
        if (!data.get('image')) validationErrors.image = 'Hình ảnh không được để trống';
        if (!data.get('expire_days') || isNaN(data.get('expire_days'))) validationErrors.expire_days = 'Ngày hết hạn phải là số nguyên';
        if (!data.get('convert_rate') || isNaN(data.get('convert_rate'))) validationErrors.convert_rate = 'Tỷ lệ chuyển đổi phải là số phần trăm';
        if (!data.get('convert_rate') || isNaN(data.get('convert_rate'))) validationErrors.convert_rate = 'Tỷ lệ chuyển đổi phải là số phần trăm';
        if (!data.get('image')) validationErrors.price = 'Giá không được để trống';
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        else {
            let imageName = uploadedImageName;
            if (imageChange && imageFile) {
                imageName = await uploadImage(imageFile);
                if (!imageName) {
                    throw new Error('Lỗi upload hình ảnh!');
                }
                setUploadedImageName(imageName);
                setImageChange(false);
            }
            const product = new Product({
                name: data.get('name'),
                description: data.get('description'),
                image: imageName,
                expire_days: parseInt(data.get('expire_days'), 10),
                convert_rate: parseFloat(data.get('convert_rate')),
            });

            try {
                const response = await product.createProduct();
                if (response.success) {
                    const productId = response.id;
                    const currentDateTime = new Date().toISOString().split('.')[0];
                    const price = new Price({
                        product_id: productId,
                        price: Number(data.get('price')),
                        actived_at: currentDateTime,
                    })
                    console.log(price);
                    const res = await price.addPrice();
                    if (res.success) {
                        console.log(res);
                        toast.success(res.message, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        navigate('/products');
                    }
                }
                else {
                    setError(response.message);
                }
            } catch (error) {
                console.error("API Response Data:", error.response.data);
                console.error("API Response Status:", error.response.status);
                console.error("API Response Headers:", error.response.headers);
                let errorMessage = "Đã xảy ra lỗi không xác định!";

                if (error.response?.data?.error) {
                    if (error.response.data.error.name) {
                        errorMessage = "Tên đã tồn tại";
                    } else if (error.response.data.error.image) {
                        errorMessage = "Ảnh phải là jpg hoặc png";
                    }
                }

                toast.error(errorMessage, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <>
            <Container maxWidth="xs" sx={{ mt: 3 }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Tên sản phẩm"
                        name="name"
                        autoComplete="name"
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Mô tả"
                        name="description"
                        autoComplete="description"
                        error={!!errors.description}
                        helperText={errors.description}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="image"
                        label="Hình ảnh"
                        id="image"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors.image}
                        helperText={errors.image}
                        onChange={handleImageChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="price"
                        label="Giá tiền"
                        name="price"
                        autoComplete="price"
                        error={!!errors.price}
                        helperText={errors.price}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="expire_days"
                        label="Ngày hết hạn (số ngày)"
                        id="expire_days"
                        type="number"
                        autoComplete="expire_days"
                        error={!!errors.expire_days}
                        helperText={errors.expire_days}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="convert_rate"
                        label="Tỷ lệ chuyển đổi (%)"
                        id="convert_rate"
                        type="number"
                        autoComplete="convert_rate"
                        error={!!errors.convert_rate}
                        helperText={errors.convert_rate}
                    />
                    <Typography sx={{ textAlign: "center", mt: 2, mb: 2 }} color="error">{error}</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Tạo mới
                    </Button>
                </Box>
            </Container>
        </>
    )

}
export default CreateProduct;