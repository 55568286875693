import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Order from '../../models/order';

const FullPackingVideo = (props) => {
    const [videos, setVideos] = useState([]);
    const [page, setPage] = useState(1);
    const videosPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        props.setPageTitle("Danh sách video");
        const fetchVideos = async () => {
            const listVideos = new Order();
            try {
                const videosData = await listVideos.getFullPackingVideo();
                setVideos(videosData);
            }
            catch (error) {
                console.error("Error fetching videos:", error);
            }
        }
        fetchVideos();
    }, [])

    console.log(videos);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const displayedVideos = videos.slice((page - 1) * videosPerPage, page * videosPerPage);

    return (
        <Container sx={{ mt: 3 }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Mã đơn hàng</TableCell>
                            <TableCell>Ngày tạo</TableCell>
                            <TableCell>Chi tiết</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedVideos.map((video) => (
                            <TableRow key={video.id}>
                                <TableCell>{video.id}</TableCell>
                                <TableCell>{video.order_code}</TableCell>
                                <TableCell>{new Date(video.created_at).toLocaleString()}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate(`/packing_video/${video.id}`)}
                                    >
                                        Xem Video
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={Math.ceil(videos.length / videosPerPage)}
                page={page}
                onChange={handleChangePage}
                sx={{ mt: 2 }}
            />
        </Container>
    )
}

export default FullPackingVideo;