import { excuseApi } from "../commons/http";

class Order {
    constructor(data = {}) {
        this.user_id = data.user_id;
        this.shipping_price = data.shipping_price;
        this.shipping_address = data.shipping_address;
        this.order_line_items = data.order_line_items;
        this.id = data.id;
        this.name = data.name;
        this.total_price = data.total_price;
        this.phone_number = data.phone_number;
        this.packing_video = data.packing_video;
        this.status = data.status;
        this.financial_status = data.financial_status;
        this.order_type = data.order_type;
        this.user_id = data.user_id;
        this.created_by = data.created_by;
        this.packaged_by = data.packaged_by;
        this.sent_invoice_by = data.sent_invoice_by;
        this.total_price = data.total_price;
        this.delivered_at = data.delivered_at;
        this.shipped_at = data.shipped_at;
        this.packaged_at = data.packaged_at;
        this.sent_invoice_at = data.sent_invoice_at;
        this.note = data.note;
        this.history = data.history;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.paid_proof = data.paid_proof;
        this.invoice_url = data.invoice_url;
        this.user = data.user;
        this.video_url = data.video_url;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
    }
    mapData(data) {
        this.user_id = data.user_id;
        this.shipping_price = data.shipping_price;
        this.shipping_address = data.shipping_address;
        this.order_line_items = data.order_line_items;
        this.id = data.id;
        this.name = data.name;
        this.total_price = data.total_price;
        this.phone_number = data.phone_number;
        this.packing_video = data.packing_video;
        this.status = data.status;
        this.financial_status = data.financial_status;
        this.order_type = data.order_type;
        this.user_id = data.user_id;
        this.created_by = data.created_by;
        this.packaged_by = data.packaged_by;
        this.sent_invoice_by = data.sent_invoice_by;
        this.total_price = data.total_price;
        this.delivered_at = data.delivered_at;
        this.shipped_at = data.shipped_at;
        this.packaged_at = data.packaged_at;
        this.sent_invoice_at = data.sent_invoice_at;
        this.note = data.note;
        this.history = data.history;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.paid_proof = data.paid_proof;
        this.invoice_url = data.invoice_url;
        this.user = data.user;
    }

    async addOrder() {
        try {
            const res = await excuseApi({
                path: '/orders',
                method: 'POST',
                body: {
                    user_id: this.user_id,
                    shipping_price: this.shipping_price,
                    shipping_address: this.shipping_address,
                    order_line_items: this.order_line_items,
                    note: this.note,
                    order_type: this.order_type,
                }
            })
            if (res.status === 200) {
                return { success: true, messenge: "Thêm đơn hàng thành công" }
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async addPackingVideoById(id, packing_video) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}/packing`,
                method: 'PUT',
                body: {
                    packing_video: packing_video,
                }
            });
            if (res.status === 200) {
                return { success: true, messenge: "Thêm video đóng hàng thành công" }
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async markAsPaid(id) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}/mark_as_paid`,
                method: 'PUT',
                body: {
                    paid_proof: this.paid_proof
                }
            });
            if (res.status === 200) {
                return { success: true, message: "Chuyển đổi trạng thái thanh toán thành công" }
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async sendingInvoice(id) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}/sending_invoice`,
                method: 'PUT',
                body: {
                    invoice_url: this.invoice_url
                }
            })
            if (res.status === 200) {
                return { success: true, message: "Trạng thái gửi hóa đơn thay đổi thành công" }
            }
        }
        catch (error) {
            throw error;
        }

    }

    async createPackingVideo() {
        try {
            const res = await excuseApi({
                path: `/orders/packing_video`,
                method: 'POST',
                body: {
                    order_code: this.name,
                    video_url: this.video_url,
                }
            })
            if (res.status === 200) {
                return { success: true, messenge: "Thêm video đóng hàng thành công" }
            }
        }
        catch (error) {
            throw error;
        }
    }

    async getFullPackingVideo() {
        try {
            const res = await excuseApi({
                path: '/orders/packing_video',
                method: 'GET',
            })
            if (res.status === 200) {
                return res.data.data;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async exportLabel(order_ids) {
        try {
            const res = await excuseApi({
                path: '/orders/packing_labels',
                method: 'POST',
                maxBodyLength: Infinity,
                responseType: 'arraybuffer',
                body: {
                    order_ids: order_ids,
                },
            })
            if (res.status === 200) {
                return { data: res.data };
            }
        }
        catch (error) {
            throw error;
        }
    }

    async getOrderByDate(page, per_page) {
        try {
            const res = await excuseApi({
                path: '/orders',
                method: 'GET',
                params: {
                    start_date: this.start_date,
                    end_date: this.end_date,
                    financial_status: this.financial_status,
                    page: page,
                    per_page: per_page,
                    name: this.name,
                    user: this.user,
                    created_by: this.created_by,
                }
            })

            console.log(res);
            if (res.status === 200 || res.status === 201) {
                return { data: res.data.data, total: res.data.total };
            }
        }
        catch (error) {
            throw error;
        }
    }

    async getInvoiceById(id) {
        try {
            const res = await excuseApi({
                path: '/orders/invoice',
                method: 'POST',
                maxBodyLength: Infinity,
                responseType: 'arraybuffer',
                body: {
                    order_ids: [id],
                }
            })
            console.log(res);
            if (res.status === 200) {
                return { data: res.data }
            }
        }
        catch (error) {
            throw error;
        }
    }

    async updateOrder(id) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}`,
                method: 'PUT',
                body: {
                    shipping_price: this.shipping_price,
                    shipping_address: this.shipping_address,
                    phone_number: this.phone_number,
                }
            })
            if (res.status === 200 || res.status === 201) {
                return { success: true, messenge: "Sửa đơn hàng thành công" }
            }
        }
        catch (error) {
            throw error;
        }
    }

    async addOrderLineItems(id, product_id, quantity) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}/line_items`,
                method: 'POST',
                body: {
                    product_id: product_id,
                    quantity: quantity,
                }
            })
            if (res.status === 200 || res.status === 201) {
                return { success: true, messenge: "Thêm sản phẩm vào đơn hàng thành công" }
            }
            else {
                return { success: false, message: "Thêm sản phẩm thất bại", error: res.data };
            }
        }
        catch (error) {
            throw error;
        }
    }

    async updateOrderLineItems(id, quantity) {
        try {
            const res = await excuseApi({
                path: `/orders/line_items/${id}`,
                method: 'PUT',
                body: {
                    quantity: quantity,
                }
            })
            if (res.status === 200 || res.status === 201) {
                return { success: true, messenge: "Thay đổi số lượng thành công", data: res.data }
            }
            else {
                return { success: false, message: "Đã xảy ra lỗi, vui lòng thử lại", error: res.data };
            }
        }
        catch (error) {
            throw error;
        }
    }

    async getOrderById(id) {
        try {
            const res = await excuseApi({
                path: `/orders/${id}`,
                method: 'GET'
            })
            if (res.status === 200 || res.status === 201) {
                return { data: res.data.data };
            }
        }
        catch (error) {
            throw error
        }
    }

}
export default Order;