import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Groups2Icon from '@mui/icons-material/Groups2';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import PostAddIcon from '@mui/icons-material/PostAdd';

const drawerWidth = 250;

const ResponsiveDrawer = (props) => {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.setItem('token', '');
    props.setLoggedIn(false);
    navigate('/login');
  }

  const drawer = (
    <List sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ListItem
        key='customers'
        component={Link}
        onClick={() => navigate('/customers')}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary='Khách Hàng' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='staffs'
        component={Link}
        onClick={() => navigate('/staffs')}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <Groups2Icon />
          </ListItemIcon>
          <ListItemText primary='Danh sách nhân viên' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='change_password'
        component={Link}
        onClick={() => navigate('/changepassword')}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <PasswordOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Đổi mật khẩu' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='orders'
        component={Link}
        onClick={() => navigate('/orders')}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary='Đơn hàng' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='products'
        component={Link}
        onClick={() => navigate('/products')}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary='Sản phẩm' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='logout'
        disablePadding
        sx={{ mb: 3, mt: "auto" }}
      >
        <ListItemButton onClick={() => logOut()}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItemButton>
      </ListItem>
    </List>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          bgcolor: 'primary.main',
        }}
        open={props.openMenu}
        className='vertical-menu'
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="persistent"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          bgcolor: 'primary.main',
        }}
        open={props.openMenu}
        className='vertical-menu'
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default ResponsiveDrawer;
