import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Card, CardContent, Box, Grid, Button, TextField, Select, MenuItem, Autocomplete } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import Orders from "../../collections/orders";
import Order from "../../models/order";
import Products from "../../collections/products";

const OrderDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [isVideoRecorded, setIsVideoRecorded] = useState(false);
    const mediaRecorderRef = useRef(null);
    const videoStreamRef = useRef(null);
    const chunks = useRef([]);
    const [packingVideo, setPackingVideo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedOrder, setEditedOrder] = useState({});
    const [products, setProducts] = useState([]);
    const [orderItems, setOrderItems] = useState({ id: 1, product: '', quantity: 1 });
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedQuantity, setEditedQuantity] = useState(null);
    const [orderLineId, setOrderLineId] = useState(null);

    const [isAddProduct, setIsAddProduct] = useState(true);

    useEffect(() => {
        const fetchOrderDetail = async () => {
            const fetchOrder = new Order();
            const data = await fetchOrder.getOrderById(id);

            setOrder(data.data);
            setVideoUrl(data.packing_video);
            setEditedOrder({
                shipping_address: data.data.shipping_address,
                phone_number: data.data.phone_number,
                shipping_price: data.data.shipping_price,
            });
        };
        fetchOrderDetail();
    }, [id, isEditing, isAddProduct, editingIndex]);
    console.log(order);
    useEffect(() => {
        const fetchProducts = async () => {
            const listProducts = new Products();
            await listProducts.fetch();
            setProducts(listProducts.items);
        }

        fetchProducts();
    }, [])


    const handleStartRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoStreamRef.current.srcObject = stream;
            videoStreamRef.current.play();

            const options = { mimeType: 'video/webm; codecs=vp8' };;
            const mediaRecorder = new MediaRecorder(stream, options);
            mediaRecorderRef.current = mediaRecorder;
            chunks.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.current.push(event.data);
                }
            };

            mediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    console.log(editedOrder);

    const handleStopRecording = () => {
        mediaRecorderRef.current.stop();
        videoStreamRef.current.srcObject.getTracks().forEach(track => track.stop());
        videoStreamRef.current.style.display = 'none';

        mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunks.current, { type: chunks.current[0]?.type || 'video/webm' });
            const videoUrl = URL.createObjectURL(blob);
            setVideoUrl(videoUrl);
            setPackingVideo(blob);
            setIsVideoRecorded(true);
            
        };

        setIsRecording(false);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('vi-VN', options);
    };

    const uploadVideo = async () => {
        let formData = new FormData();
        formData.append('file', packingVideo, 'packing-video.webm');
        let config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: 'https://api.maysfarm.vn/api/v1/images',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJuZ3V5ZW50aGFpMjgxMUBnbWFpbC5jb20iLCJyb2xlIjoiQURNSU4iLCJleHAiOjEwMDAwMDAwMDAwMDE3MjQ4NDY5NTh9.KtSXIt4Du_lhqfZNwyGCETlCW9BaIXUujjSW60SfaqY',
            },
            data: formData
        };
        try {
            const res = await axios.request(config);
            return res.data.data;
        }
        catch (error) {
            throw error;
        }
    }
    const handleSendVideo = async () => {
        const video = await uploadVideo();
        const sendVideo = new Order();
        try {
            const response = await sendVideo.addPackingVideoById(id, video);
            if (response.success) {
                toast.success(response.messenge, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                navigate("/orders");
            }
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount).replace("₫", "");
    };

    const handleEditToggle = () => setIsEditing(!isEditing);

    const handleInputChange = (field, value) => {
        setEditedOrder(prev => ({ ...prev, [field]: value }));
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedOrder({
            shipping_address: order.shipping_address,
            phone_number: order.phone_number,
            shipping_price: order.shipping_price,
        });
    };

    const handleConfirmEdit = async () => {
        const newOrder = new Order({
            shipping_price: editedOrder.shipping_price,
            shipping_address: editedOrder.shipping_address,
            phone_number: editedOrder.phone_number,
        })
        try {
            const res = await newOrder.updateOrder(id);
            if (res.success) {
                toast.success(res.messenge, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                navigate(`/orders/${id}`);
            }
        }
        catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            navigate(`/orders/${id}`);
        }
        setIsEditing(false);
    };

    const handleQuantityChange = (value) => {
        setOrderItems({ ...orderItems, quantity: value });
    };
    const handleRemoveItem = () => {
        setOrderItems([{ id: 1, product: '', quantity: 1 }])
    };
    const handleProductChange = (value) => {
        const newItems = { ...orderItems };
        newItems.product = value;
        setOrderItems(newItems);
    };
    const handleAddProduct = async () => {
        const order = new Order();
        try {
            const res = await order.addOrderLineItems(id, orderItems.product, parseInt(orderItems.quantity));
            if (res.success) {
                toast.success(res.messenge, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsAddProduct(true);
                setOrderItems([{ id: 1, product: '', quantity: 1 }]);
            }
        }
        catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            navigate(`/orders/${id}`);
        }
    }

    const handleClickThem = () => {
        toast.info(
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <p>Bạn có xác nhận thêm sản phẩm?</p>
                <Box display="flex" gap={2} mt={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            handleAddProduct();
                            toast.dismiss();
                        }}
                    >
                        Có
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        Không
                    </Button>
                </Box>
            </Box>,
            {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
    }

    const handleEdit = () => {
        toast.info(
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <p>Bạn có xác nhận chỉnh sửa không?</p>
                <Box display="flex" gap={2} mt={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            handleConfirmEdit();
                            toast.dismiss();
                        }}
                    >
                        Có
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        Không
                    </Button>
                </Box>
            </Box>,
            {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
    };

    const handleClickAddProduct = () => {
        setIsAddProduct(!isAddProduct);
    }

    const handleEditQuantity = (index, quantity) => {
        setEditingIndex(index);
        setEditedQuantity(quantity);
    };

    const handleCancelEditQuantity = () => {
        setEditingIndex(null);
        setEditedQuantity(null);
        setOrderLineId(null);
    };

    const handleConfirmEditQuantity = () => {
        toast.info(
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
            >
                <p>Bạn có xác nhận chỉnh sửa không?</p>
                <Box display="flex" gap={2} mt={2}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            handleChangeQuantity();
                            toast.dismiss();
                        }}
                    >
                        Có
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            toast.dismiss();
                        }}
                    >
                        Không
                    </Button>
                </Box>
            </Box>,
            {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
    };

    const handleChangeQuantity = async () => {
        const order = new Order();
        try {
            const res = await order.updateOrderLineItems(orderLineId, parseInt(editedQuantity));
            console.log(res.error);
            if (res.success) {
                toast.success(res.messenge, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setEditingIndex(null);
                setEditedQuantity(null);
                setOrderLineId(null);
            }
        }
        catch (error) {
            toast.error("Đã xảy ra lỗi, vui lòng thử lại", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setEditingIndex(null);
            setEditedQuantity(null);
            setOrderLineId(null);
        }
    }


    // const handleDownloadInvoice = async () => {
    //     const order = new Order();
    //     try {
    //         const res = await order.getInvoiceById(id);
    //         console.log(res);
    //         if (res.data) {
    //             const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
    //             const downloadLink = window.URL.createObjectURL(pdfBlob);
    //             downloadPDF(downloadLink);
    //         }
    //     }
    //     catch (error) {
    //         throw error;
    //     }
    // }

    // const downloadPDF = (link) => {
    //     const linkElement = document.createElement('a');
    //     linkElement.href = link;
    //     linkElement.download = `label.pdf`;
    //     document.body.appendChild(linkElement);
    //     linkElement.click();
    //     document.body.removeChild(linkElement);
    // };

    if (!order) {
        return <Typography>Đang tải...</Typography>;
    }

    return (
        <Container sx={{ mt: 3 }}>
            <Card>
                <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} display="flex" alignItems="center">
                                <ShoppingCartIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h5" gutterBottom>
                                    Chi tiết đơn hàng #{order.user.name} - {order.user.phone_number}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} display="flex" justifyContent="flex-end">
                                {order.status !== "SENT_INVOICE" && (
                                    !isEditing ? (
                                        <Button variant="contained" color="primary" onClick={handleEditToggle}>
                                            Chỉnh sửa đơn hàng
                                        </Button>
                                    ) : (
                                        <Box>
                                            <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={handleCancelEdit}>
                                                Hủy
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleEdit}>
                                                Xác nhận
                                            </Button>
                                        </Box>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <DateRangeIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h6">Ngày đặt hàng: {formatDate(order.created_at)}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />
                                <Typography variant="h6">
                                    Loại đơn hàng: {order.order_type === 'INSTANT_ORDER' ? 'Mua lẻ' : order.order_type === 'MONTHLY_PACKAGE_ORDER' ? 'Gói tháng' : order.order_type}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <LocalPhoneIcon color="primary" sx={{ mr: 1 }} />
                                {!isEditing ? (
                                    <Typography variant="h6">Điện thoại: {order.phone_number}</Typography>
                                ) : (
                                    <TextField
                                        fullWidth
                                        value={editedOrder.phone_number}
                                        onChange={(e) => handleInputChange('phone_number', e.target.value)}
                                        label="Số điện thoại"
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center">
                                <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />
                                {!isEditing ? (
                                    <Typography variant="h6">Phí vận chuyển: {formatCurrency(order.shipping_price)}</Typography>
                                ) : (
                                    <Autocomplete
                                        sx={{ mt: -2 }}
                                        fullWidth
                                        value={order.shipping_price}
                                        onChange={(event, newValue) => handleInputChange('shipping_price', newValue)}
                                        options={[0, 10000, 15000, 25000, 29000, 39000]}
                                        getOptionLabel={(option) => {
                                            return new Intl.NumberFormat('vi-VN').format(option);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            const cleanValue = newInputValue.replace(/\./g, '');
                                            if (/^\d*$/.test(cleanValue)) {
                                                handleInputChange('shipping_price', cleanValue === '' ? '' : Number(cleanValue));
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tiền Ship"
                                                variant="outlined"
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    onKeyPress: (e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    },
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                {new Intl.NumberFormat('vi-VN').format(option)}
                                            </li>
                                        )}
                                        freeSolo
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={12} sm={12}>
                            <Box display="flex" alignItems="center">
                                <LocalShippingIcon color="primary" sx={{ mr: 1 }} />
                                {!isEditing ? (
                                    <Typography variant="h6">Địa chỉ: {order.shipping_address}</Typography>
                                ) : (
                                    <TextField
                                        fullWidth
                                        value={editedOrder.shipping_address}
                                        onChange={(e) => handleInputChange('shipping_address', e.target.value)}
                                        label="Địa chỉ"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" alignItems="center">
                                    {order.order_type !== "MONTHLY_PACKAGE_ORDER" ? (
                                        <>
                                            <Typography variant="h6" sx={{ mr: 2 }}>Thanh toán:</Typography>
                                            {order.paid_proof ? (
                                                <Typography color="green" variant="body1">Đã Thanh Toán</Typography>
                                            ) : (
                                                <Box display="flex" alignItems="center">
                                                    <Typography color="red" variant="body1" sx={{ mr: 2 }}>Chưa Thanh Toán</Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => navigate(`/orders/${order.id}/paid_proof`)}
                                                    >
                                                        Thanh Toán
                                                    </Button>
                                                </Box>
                                            )}
                                        </>
                                    ) : null}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" sx={{ mr: 2 }}>Tải hóa đơn: </Typography>
                                    {order.invoice_url ? (
                                        <Typography color="green" variant="body1">Đã Gửi Hóa Đơn</Typography>
                                    ) : (
                                        <Box display="flex" alignItems="center">
                                            <Typography color="red" variant="body1" sx={{ mr: 2 }}>Chưa Gửi Hóa Đơn</Typography>
                                            {/* <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleDownloadInvoice()}
                                            >
                                                Tải Hóa Đơn
                                            </Button> */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => navigate(`/orders/${order.id}/send_invoice`)}
                                                sx={{ ml: 2 }}
                                            >
                                                Tải và gửi hóa đơn
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={{ mb: 1 }}>Danh sách sản phẩm:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {isAddProduct && order.status !== "SENT_INVOICE" && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={handleClickAddProduct}
                                    >
                                        Thêm sản phẩm
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                    <TableContainer sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Table sx={{ width: '100%', minWidth: '600px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Tên sản phẩm</TableCell>
                                    <TableCell align="center">Hình ảnh</TableCell>
                                    <TableCell align="center">Số lượng</TableCell>
                                    <TableCell align="center">Ghi chú</TableCell>
                                    <TableCell align="center">Đơn giá</TableCell>
                                    <TableCell align="center">Thành tiền</TableCell>
                                    {order.status === "OPEN" || order.status === "PACKAGED"
                                        ?
                                        <TableCell align="center">Hành động</TableCell>
                                        : null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.order_line_items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{item.product.name}</TableCell>
                                        <TableCell align="center">
                                            {item.product.image ? <img src={item.product.image} alt={item.product.name} style={{ width: '100px', height: '100px' }} /> : null}
                                        </TableCell>
                                        <TableCell align="center">
                                            {editingIndex === index ? (
                                                <TextField
                                                    type="text"
                                                    value={editedQuantity}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*\.?\d*$/.test(value)) {
                                                            setEditedQuantity(value);
                                                            setOrderLineId(item.id);
                                                        }
                                                    }}
                                                    size="small"
                                                    sx={{ width: '60px' }}
                                                />
                                            ) : (
                                                item.quantity
                                            )}
                                        </TableCell>
                                        <TableCell align="center">{item.note}</TableCell>
                                        <TableCell align="center">{item.cost}</TableCell>
                                        <TableCell align="center">{formatCurrency(item.total_price)}</TableCell>
                                        {order.status === "OPEN" || order.status === "PACKAGED" ? (
                                            <TableCell align="center">
                                                {editingIndex === index ? (
                                                    <>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            sx={{ ml: 2, mt: 1, padding: '12px 24px' }}
                                                            onClick={handleCancelEditQuantity}
                                                        >
                                                            Hủy
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ ml: 2, mt: 1, padding: '12px 24px' }}
                                                            onClick={handleConfirmEditQuantity}
                                                        >
                                                            Xác nhận sửa
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ ml: 2, mt: 1, padding: '12px 24px' }}
                                                        onClick={() => handleEditQuantity(index, item.quantity)}
                                                    >
                                                        Chỉnh sửa
                                                    </Button>
                                                )}
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ mt: 3 }}>
                        <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6">
                            Tổng tiền: {formatCurrency(order.total_price)}
                        </Typography>
                    </Box>
                    {!isAddProduct ?
                        <>
                            <Typography
                                variant="h4"
                                sx={{
                                    mb: 1,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    mt: 4
                                }}
                            >
                                Thêm sản phẩm
                            </Typography>
                            <TableContainer sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Table sx={{ width: '100%', minWidth: '600px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Sản Phẩm</TableCell>
                                            <TableCell align="center">Số Lượng</TableCell>
                                            <TableCell align="center">Xóa</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>

                                            <TableCell align="center">
                                                <Autocomplete
                                                    options={products}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    value={products.find(p => p.id === orderItems.product) || null}
                                                    onChange={(event, newValue) => handleProductChange(newValue ? newValue.id : '')}
                                                    renderInput={(params) => <TextField {...params} label="Sản Phẩm" fullWidth />}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    type="number"
                                                    value={orderItems.quantity}
                                                    onChange={(e) => handleQuantityChange(e.target.value)}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="outlined" onClick={() => handleRemoveItem()}>X</Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" colSpan={3}>
                                                {!isAddProduct ?
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        sx={{ ml: 2, mt: 3, mr: 3, padding: '12px 24px' }}
                                                        onClick={handleClickAddProduct}
                                                    >
                                                        Hủy
                                                    </Button>
                                                    : null}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ ml: 2, mt: 3, padding: '12px 24px' }}
                                                    onClick={handleClickThem}
                                                >
                                                    Thêm
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        null
                    }

                    {/* <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={isRecording ? handleStopRecording : handleStartRecording}
                            sx={{ fontSize: '18px', padding: '10px 20px' }}
                        >
                            {isRecording ? 'Dừng Quay' : 'Quay lại quá trình đóng hàng'}
                        </Button>
                    </Box> */}

                    {videoUrl && (
                        <Box mt={2} textAlign="center">
                            <Typography variant="h6">Video Đã Ghi:</Typography>
                            <video src={videoUrl} controls style={{ width: '500px' }}></video>
                        </Box>
                    )}

                    <Box mt={2} textAlign="center">
                        <video ref={videoStreamRef} style={{ width: '500px' }} autoPlay muted></video>
                    </Box>

                    {isVideoRecorded && (
                        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendVideo}
                                sx={{ fontSize: '18px', padding: '10px 20px' }}
                            >
                                Gửi Video
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Container>
    );
};

export default OrderDetail;