import React from "react";
import "../css/homepagecss.css";
import { useState } from "react";

const Homepage = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    return (
        <div>
            <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                <div className="container w-container">
                    <a href="#" className="brand-block w-clearfix w-nav-brand">
                        <img src="path/to/logo.png" alt="Logo" className="logo-img" />
                        <h1 className="logo-title">Mây's Farm</h1>
                    </a>
                    <nav role="navigation" className="nav-menu w-nav-menu">
                        <a href="#process" className="nav-link w-nav-link">Về chúng tôi</a>
                        <a href="/project-page" className="nav-link w-nav-link">Rau củ</a>
                        <a href="#" className="nav-link w-nav-link">Hoa quả theo mùa</a>
                        <a href="#" className="nav-link w-nav-link">Giỏ hàng</a>
                        <a href="#" className="nav-link w-nav-link">Đăng nhập</a>
                    </nav>
                    <div className="menu-button w-nav-button" onClick={toggleMenu}>
                        <div className={`icon w-icon-nav-menu ${menuOpen ? 'w--open' : ''}`}>menu</div>
                    </div>
                </div>
            </div>
            <div style={{ background: "black" }}>
                {menuOpen
                    ?
                    <nav role="navigation" className="nav-menu-open w-nav-menu">
                        <a href="#process" className="nav-link w-nav-link">Về chúng tôi</a>
                        <a href="/project-page" className="nav-link w-nav-link">Rau củ</a>
                        <a href="#" className="nav-link w-nav-link">Hoa quả theo mùa</a>
                        <a href="#" className="nav-link w-nav-link">Giỏ hàng</a>
                        <a href="#" className="nav-link w-nav-link">Đăng nhập</a>
                    </nav>
                    : ''}
            </div>
            <div className="header">
                <div className="w-container">
                    <h1 className="main-heading">Sức khỏe của khách hàng là ưu tiên hàng đầu</h1>
                    <div className="divider"></div>
                    <div className="main-subtitle">FRESH & SAFE</div>
                </div>
            </div>
            <div className="about-section">
                <img src="path/to/image.jpg" alt="About us" />
                <div className="w-container">
                    <p className="about-text">Rất nhiều dòng giới thiệu</p>
                    <a href="#contact" className="button">Liên hệ với chúng tôi</a>
                </div>
            </div>
            <div id="projects" className="section grey">
                <div className="w-container">
                    <h2 className="heading-2">Danh sách rau ngày</h2>
                    <div className="divider grey"></div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 1" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO RAU CỦ ÉP NƯỚC</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 2" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO SALAD</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 3" />
                                <div className="project-info">
                                    <h3 className="project-title">Danh sách rau củ</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 4" />
                                <div className="project-info">
                                    <h3 className="project-title">CÁC LOẠI HẠT</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 5" />
                                <div className="project-info">
                                    <h3 className="project-title">TRÁI CÂY</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Project 6" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO QUÀ BIẾU</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="projects" className="section grey">
                <div className="w-container">
                    <h2 className="heading-2">Sản phẩm của Mây</h2>
                    <div className="divider grey"></div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 1" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO RAU CỦ ÉP NƯỚC</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 2" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO SALAD</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 3" />
                                <div className="project-info">
                                    <h3 className="project-title">Danh sách rau củ</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 4" />
                                <div className="project-info">
                                    <h3 className="project-title">CÁC LOẠI HẠT</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 5" />
                                <div className="project-info">
                                    <h3 className="project-title">TRÁI CÂY</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 6" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO QUÀ BIẾU</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="projects" className="section grey">
                <div className="w-container">
                    <h2 className="heading-2">Trang trại của Mây</h2>
                    <div className="divider grey"></div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 1" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO RAU CỦ ÉP NƯỚC</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 2" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO SALAD</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 3" />
                                <div className="project-info">
                                    <h3 className="project-title">Danh sách rau củ</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="w-row">
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 4" />
                                <div className="project-info">
                                    <h3 className="project-title">CÁC LOẠI HẠT</h3>
                                    <div>RAU CỦ</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 5" />
                                <div className="project-info">
                                    <h3 className="project-title">TRÁI CÂY</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4">
                            <a href="/project-page" className="project-wrapper w-inline-block">
                                <img src="path/to/image.jpg" alt="Product 6" />
                                <div className="project-info">
                                    <h3 className="project-title">COMBO QUÀ BIẾU</h3>
                                    <div>TRÁI CÂY THEO MÙA</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="process" class="section">
                <div class="w-container">
                    <h2 class="heading-4">Đôi điều về Mây's Farm</h2>
                    <div class="divider grey"></div>
                    <div class="w-row">
                        <div class="w-col w-col-4">
                            <div class="grey-icon-wrapper">
                                <img src="path/to/image.jpg" alt="Product 6" />
                            </div>
                            <h3>Điều thứ nhất</h3>
                            <p>Bla bla bla</p>
                        </div>
                        <div class="w-col w-col-4">
                            <div class="grey-icon-wrapper">
                                <img src="path/to/image.jpg" alt="Product 6" />
                            </div>
                            <h3>Điều thứ 2</h3>
                            <p>Bla bla bla</p>
                        </div>
                        <div class="w-col w-col-4">
                            <div class="grey-icon-wrapper">
                                <img src="path/to/image.jpg" alt="Product 6" />
                            </div>
                            <h3>Điều thứ 3</h3>
                            <p>Bla bla bla</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;