import { excuseApi } from "../commons/http";

class Price {
    constructor(data = {}) {
        this.product_id = data.product_id;
        this.price = data.price;
        this.actived_at = data.actived_at;
    }
    mapData(data) {
        this.product_id = data.product_id;
        this.price = data.price;
        this.actived_at = data.actived_at
    }

    async addPrice() {
        try{
            const res = await excuseApi({
                path:'/history_product_prices',
                method: 'POST',
                body: {
                    product_id: this.product_id,
                    price: this.price,
                    actived_at: this.actived_at,
                }
            })
            if (res.status === 200){
                return {success: true, message: 'Thêm giá thành công'};
            }
            else{
                return null;
            }
        }
        catch(error){
            throw error;
        }
    }

    async getPriceById(id) {
        try {
            const res = await excuseApi({
                path: `/history_product_prices`,
                method: 'GET',
                params: {
                    product_id: id
                }
            });
            if (res.status === 200) {
                console.log(res.data);
                return res.data.data;
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }

    async updatePrice(id) {
        try {
            const res = await excuseApi({
                path: `/history_product_prices/${id}`,
                method: 'PUT',
                body: {
                    product_id: this.product_id,
                    price: this.price,
                    actived_at: this.actived_at,
                }
            });
            if (res.status === 200) {
                return { sucess: true, messenge: "Thay đổi giá thành công" }
            }
            else {
                return null;
            }
        }
        catch (error) {
            throw error;
        }
    }
}
export default Price;