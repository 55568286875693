import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Account from '../../models/account';

const NewStaff = (props) => {
  const controlRole = props.controlRole;
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('ACTIVE');
  const [error, setError] = useState('');
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    props.setPageTitle('Tạo mới nhân viên');
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let validationErrors = {};

    if (!data.get('name')) validationErrors.name = 'Họ tên nhân viên không được để trống';
    if (!data.get('email')) validationErrors.email = 'Email không được để trống';
    if (!data.get('password')) validationErrors.password = 'Mật khẩu không được để trống';
    if (!data.get('role')) validationErrors.role = 'Quyền truy cập không được để trống';
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const account = new Account({
        name: data.get('name'),
        email: data.get('email'),
        password: data.get('password'),
        role: data.get('role'),
        status: data.get('status')
      })
      try {
        const response = await account.save();
        if (response.success) {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          navigate('/staffs');
        }
        else {
          setError(response.message);
        }
      }
      catch (error) {
        const errorMessage = error.response?.data?.error || "Đã xảy ra lỗi không xác định!";
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <>
      {controlRole === "ADMIN" ?
        <>
          <Container maxWidth="xs" sx={{ mt: 3 }}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Họ tên nhân viên"
                name="name"
                autoComplete="name"
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Địa chỉ email"
                name="email"
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ mt: 1 }}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                id="role"
                name="role"
                select
                value={selectedRole}
                onChange={handleRoleChange}
                label="Quyền truy cập"
                required
                fullWidth
                sx={{ mt: 1 }}
                error={!!errors.role}
                helperText={errors.role}
              >
                <MenuItem value='ADMIN'>Quản lý</MenuItem>
                <MenuItem value='STAFF'>Nhân viên</MenuItem>
              </TextField>
              <TextField
                id="status"
                name="status"
                select
                value={selectedStatus}
                onChange={(event) => handleStatusChange(event)}
                label="Trạng thái"
                required
                fullWidth
                sx={{ mt: 2 }}
              >
                <MenuItem value='ACTIVE'>Hoạt động</MenuItem>
                <MenuItem value='INACTIVE'>Dừng hoạt động</MenuItem>
              </TextField>
              <Typography sx={{ textAlign: "center", mt: 2, mb: 2 }} color="error">{error}</Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Tạo mới
              </Button>
            </Box>
          </Container>
        </>
        :
        <Typography component="h1" variant="h5" sx={{ textAlign: "center", mt: 5 }}>
          Bạn không đủ thẩm quyền để truy cập vào tính năng này
        </Typography>
      }
    </>
  )
}

export default NewStaff;